.footer {
    padding-top: 2rem;
  }
  
  .footer__container {
    row-gap: 3.5rem;
  }
  
  .footer__bg {
    /* background-color: hsl(var(--clr-light-300)); */
    padding: 2rem 0 3rem ;
  }
  
  .footer__title {
    font-size: var(--h1-fs);
    margin-bottom: var(--mb-0-25);
  }
  
  .footer__subtitle {
    font-size: var(--small-fs);
  }
  
  .footer__links {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
  
  .footer__link:hover, .footer__social:hover {
    color: hsl(var(--clr-dark-hover));
  }
  
  .footer__social {
    font-size: 1.25rem;
    margin-right: var(--mb-1-5);
  }
  
  .footer__title,
  .footer__link,
  .footer__subtitle,
  .footer__social {
    color: hsl(var(--clr-black));
  }