.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: hsl(var(--clr-dark));
  opacity: .8;
  padding: 0 .3rem;
  border-radius: .4rem;
  z-index: var(--z-tooltip);
  transition: .4s;
}

.scrollup:hover {
  background-color: hsl(var(--clr-dark-hover));
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #FFF;
}

/* Show scroll */
.show-scroll {
  bottom: 5rem;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
  width: 5px;
  border-radius: .5rem;
  background-color: hsl(var(--clr-light-100));
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--clr-light-200));
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(var(--clr-dark-hover));
}