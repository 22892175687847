* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
  scroll-behavior: smooth;
}

.app {
  /* margin: 0 0 var(--header-height) 0; */
  font-family: var(--body-font) ;
  font-size: var(--normal-fs);
  background-color: hsl(var(--clr-light-100));
  color: hsl(var(--clr-text));
}

h1,h2,h3,h4 {
  color: hsl(var(--clr-black));
  font-weight: var(--fw-semi-bold);
}

.section__remark h1,
.section__remark h2,
.section__remark h3,
.section__remark h4 {
  color: hsl(var(--clr-black-remark));
}

.section__remark p,
.section__remark span {
  color: hsl(var(--clr-text-remark));
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== utility classes ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section__remark {
  background-color: hsl(var(--clr-light-300));
}

.section__title {
  font-size: var(--h1-fs);
}

.section__subtitle {
  display: block;
  font-size: var(--small-fs);
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background: hsl(var(--clr-light-100));
}

@media screen and (min-width: 768px) {
  .section {
    padding: 6rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  .header {
    padding:  0 1rem;
  }
}