.work__container {
  padding: 1rem;
  grid-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  height: 800px;
  overflow-y: scroll;
}

.work__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: .75rem;
  margin-bottom: 2rem;
}

.work__item {
  cursor: pointer;
  color: hsl(var(--clr-black));
  padding: .25rem .75rem;
  font-weight: var(--fw-medium);
  border: 1px solid hsl(var(--clr-black));
  border-radius: .5rem;
}

.work__card {
  background-color: var(--clr-card-container);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 19.375rem;
}

.work__card-info {
  padding: 1rem;
}

.work__img {
  height: 190px;
}

.work__img img {
  border-radius: 1rem 1rem 0 0;
  object-fit: cover;
  object-position: top left;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid hsl(var(--clr-light-200));
}

.work__title {
  font-size: var(--normal-fs);
  font-weight: var(--fw-semi-bold);
  margin-bottom: .25rem;
}

.flex__project {
  display: flex;
  gap: 0.5rem;
  margin: .5rem 0 0;
}

.work__button {
  width: max-content;
  color: hsl(var(--clr-dark));
  font-size: var(--small-fs);
  font-weight: var(--fw-medium);
  display: flex;
  align-items: center;
  column-gap: .25rem;
}

.work__button:hover .work__icon {
  transform: translateX(.25rem);
}

.work__icon {
  font-size: 1rem;
  transition: .4s;
}

/* Active item work */
.active-work {
  background-color: hsl(var(--clr-black));
  color: hsl(var(--clr-light-100));
}

.flex_info_project {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon_info:hover {
  color: hsl(var(--clr-dark));
}

@media screen and (max-width: 350px) and (min-width: 768px) {
  .work__container {
    padding-top: 1rem;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    height: 400px;
  }
}

@media screen and (min-width: 568px) {
  .work__container {
    padding-top: 1rem;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    height: 600px;
  }

  .work__img {
    height: 240px;
  }

  .work__card {
    max-height: 25rem;
  }
}

@media screen and (min-width: 768px) {
  .container {
    margin-inline: auto;
  }

  .work__container {
    padding-top: 2rem;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    height: 400px;
  }

  .work__img {
    height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .work__container {
    grid-gap: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    height: 600px;
    width: 80%;
    max-width: 80rem;
  }

  .work__card {
    max-height: 23.125rem;
  }
}