.nav {
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__toggle,
.nav__logo {
  color: hsl(var(--clr-black));
  font-weight: var(--fw-medium);
}

.nav__logo h3 {
  display: inline;
}

.nav__logo span {
  display: inline-block;
  border-radius: 50%;
  background-color: hsl(var(--clr-dark));
  width: 5px;
  aspect-ratio: 1;
  margin-left: .2em;
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
}

.nav__logo:hover,
.nav__toggle:hover,
.change-theme:hover,
.nav__link:hover {
  color: hsl(var(--clr-dark-hover));
  cursor: pointer;
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-fs);
  color: hsl(var(--clr-black));
  font-weight: var(--fw-medium);
}

.nav__icon {
  font-size: 1.2rem;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: .5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: hsl(var(--clr-dark));
}

.nav__close:hover {
  color: hsl(var(--clr-dark-hover));
}

.show-menu {
  bottom: 0;
}

.hide-menu {
  bottom: -100%;
}

/* Active link */
.active-link {
  color: hsl(var(--clr-dark));
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
}

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25rem;
  color: hsl(var(--clr-black));
  margin-right: var(--mb-1);
  cursor: pointer;
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem .25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: hsl(var(--clr-light-100));
    padding: 2rem 1.5rem 4rem;
    border-radius: 1.5rem 1.5rem 0 0;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
    transition: .3s;
  }
}

@media screen and (min-width: 768px) {
  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 2rem;
  }

  .header {
    top: 0;
    bottom: initial;
    padding: 0 1rem;
  }
}