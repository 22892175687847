.services__content {
  position: relative;
  background-color: hsl(var(--clr-light-100));
  padding: 3.5rem .5rem 1.25rem 1.5rem;
  border-radius: .25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
  transition: .3s;
}

.services__content:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
}

.services__icon {
  display: block;
  font-size: 1.5rem;
  color: hsl(var(--clr-dark));
  margin-bottom: var(--mb-1);
}

.services__title {
  font-size: var(--h3-fs);
  margin-bottom: var(--mb-1);
  font-weight: var(--fw-medium);
}

.services__button{
  cursor: pointer;
  font-size: var(--small-fs);
  font-size: large;
}

.services__button:hover .button__icon {
  transform: translateX(.25rem);
}

.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-modal);
  transition: .3s;
}

.services__modal-content {
  position: relative;
  background-color: hsl(var(--clr-light-100));
  padding: 1.5rem;
  border-radius: .5rem;
  width: 90%;
max-width: 32.5rem;
}

.services__modal-services {
  row-gap: 1rem;
}

.services__modal-service {
  display: flex;
}

.services__modal-title {
  font-size: var(--h3-fs);
  font-weight: var(--fw-medium);
  margin-bottom: var(--mb-1-5);
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: hsl(var(--clr-dark));
  cursor: pointer;
}

.services__modal-icon {
  color: hsl(var(--clr-dark));
  margin-right: var(--mb-0-25);
}

.tech__icon {
  width: 2rem;
}

.flex__tech {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.flex__project {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0 0;
}

.flex__project-techs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

.skills__name {
  font-size: var(--normal-fs);
  font-weight: var(--fw-medium);
}