.home__container {
  max-width: 39ch;
  margin-inline: auto;
  padding-inline: var(--mb-1-5);
}

.home__img {
  margin-bottom: var(--mb-2);
  filter: saturate(110%) contrast(110%);
}

.home__content {
  max-width: 36ch;
}

.home__content>*+* {
  margin-top: var(--mb-1-5);
}

.home__title {
  font-size: var(--big-fs);
  font-weight: var(--fw-bold);
  line-height: 1.3;
}

.home__description {
  margin-block: var(--mb-1) var(--mb-2);
}

span.home__highlight {
  font-weight: 600;
  font-style: oblique;
  color: hsl(var(--clr-dark));
}

.home__social {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.home__social-icon {
  font-size: 1.25rem;
  color: hsl(var(--clr-dark));
}

.home__social-icon:hover {
  color: hsl(var(--clr-dark-hover));
}

@media screen and (min-width: 568px) {
  .home__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 90%;
    max-width: 54rem;
    padding: 3.5rem 0 0 0;
    justify-content: space-between;
  }

  .home__img, .home__content {
    width: 45%;
  }

  .home__content {
    max-width: 36ch;
  }

}